import hero from '../Assets/images/hero.svg';
import joinArrow from '../Assets/images/joinarrow.svg';
import { useNavigate } from 'react-router-dom';

export default function SectionHero() {
    const navigate = useNavigate();

    return (
        <section class="SectionHero relative relative overflow-hidden">
            <div className="container mx-auto p-2">
                <div className="flex w-full py-20 px-6">
                    <div className="flex-1 gap-5">
                        <div className="flex flex-col justify-start items-start gap-12">
                            <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-7">
                                <p className="self-stretch flex-grow-0 flex-shrink-0 w-[584px] text-[64px] font-black text-left capitalize cursor-default">
                                    <span className="self-stretch flex-grow-0 flex-shrink-0 w-[584px] text-[64px] font-black text-left capitalize text-[#2a3470]">Start </span>
                                    <span className="self-stretch flex-grow-0 flex-shrink-0 w-[584px] text-[64px] font-black text-left capitalize text-[#0019b3]">Exploring </span>
                                    <span className="self-stretch flex-grow-0 flex-shrink-0 w-[584px] text-[64px] font-black text-left capitalize text-[#2a3470]">the World Today</span>
                                </p>
                                <p className="flex-grow-0 flex-shrink-0 w-[518px] text-lg text-left text-[#7178a1] cursor-default">
                                    Join GASA Armenia and unlock exclusive travel opportunities as a Pegasus partner. Expand
                                    your clients’ horizons with seamless journeys through a globally trusted airline.
                                </p>
                            </div>
                            <div
                                onClick={() => {
                                    navigate("/join");
                                }} className="JoinButton flex justify-center items-center flex-grow-0 flex-shrink-0 h-[60px] relative overflow-hidden px-[5px] rounded-[200px] bg-[#2a3470] hover:bg-[#4555B8] transition duration-300 ease-in-out cursor-pointer">
                                <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 w-[50px] h-[50px] relative overflow-hidden rounded-[200px] bg-white">
                                    <img src={joinArrow} className="relative select-none cursor-pointer" draggable="false" alt="" />
                                </div>
                                <span className="flex-grow-0 flex-shrink-0 w-[143px] select-none text-lg font-bold text-center uppercase text-white">
                                    Join US
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="flex-1">
                        <div className="flex justify-right items-bottom absolute w-1/2 ">
                            <img
                                src={hero}
                                draggable="false"
                                alt=""
                                className="w-full h-full"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};