import React, { useState, useRef, useEffect } from "react";
import GasaMapImage from '../Assets/images/GasaMap.svg';

const Slide = React.forwardRef(({ children }, ref) => (
  <div className="simple-slide" ref={ref}>{children}</div>
));

const SlideList = ({ children, currentIndex }) => {
  return React.Children.map(children, (child, index) => (
    <div
      className={`flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2.5 p-3 rounded-[100px] ${index === currentIndex ? "bg-[#e5e9ff]" : ""}`}
    >
      <p className={`cursor-pointer flex-grow-0 flex-shrink-0 text-xl font-bold text-center ${index === currentIndex ? "text-[#2a3470]" : "text-[#A3A6B8]"}`}>{child}</p>
    </div>
  ));
};

export default function Slider({ children, leftCallback = () => {}, rightCallback = () => {} }) {
  const [width, setWidth] = useState(0);
  const [offset, setOffset] = useState(0);
  const [slideCount, setSlideCount] = useState(React.Children.count(children));
  const [currentIndex, setCurrentIndex] = useState(0);

  const slideRef = useRef(null);
  const wrapperRef = useRef(null);
  const slideWindowRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect();
      setWidth(rect.width);
      slideWindowRef.current.style.width = `${rect.width}px`;
    }
  }, [children]);

  const leftClick = (e) => {
    e.preventDefault();
    if (currentIndex === 0) return;
    const newIndex = currentIndex - 1;
    setCurrentIndex(newIndex);
    setOffset(newIndex * width);
    wrapperRef.current.style.transform = `translateX(-${newIndex * width}px)`;
    leftCallback();
  };

  const rightClick = (e) => {
    e.preventDefault();
    if (currentIndex === slideCount - 1) return;
    const newIndex = currentIndex + 1;
    setCurrentIndex(newIndex);
    setOffset(newIndex * width);
    wrapperRef.current.style.transform = `translateX(-${newIndex * width}px)`;
    rightCallback();
  };

  return (
    <div className="flex w-full flex-col">
      <div className="yearSlider flex w-full" style={{ backgroundImage: `url(${GasaMapImage})` }}>
        <div className="main-slider w-full flex items-center">
          <button onClick={leftClick} disabled={currentIndex === 0}>
            <div className="flex justify-center items-center w-[60px] h-[60px] p-3 rounded-full bg-[#f4f6ff]">
              <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5 20L1.5 11L10.5 2" stroke="#2A3470" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </button>
          <div className="slide-window overflow-hidden w-full" ref={slideWindowRef}>
            <div className="slide-wrapper flex" ref={wrapperRef} style={{ transition: 'transform 0.3s ease' }}>
              {React.Children.map(children, (child, index) => (
                <Slide key={index} ref={slideRef}>{child}</Slide>
              ))}
            </div>
          </div>
          <button onClick={rightClick} disabled={currentIndex === slideCount - 1}>
            <div className="flex justify-center items-center w-[60px] h-[60px] p-3 rounded-full bg-[#f4f6ff]">
              <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.5 20L10.5 11L1.5 2" stroke="#2A3470" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </button>
        </div>
      </div>
      <div className="yearListContainer">
        <div className="yearList flex flex-wrap justify-between items-center px-[100px]">
            <SlideList currentIndex={currentIndex}>{children}</SlideList>
        </div>
      </div>
    </div>
  );
}
