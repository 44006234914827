import hero from '../Assets/images/hero.svg';
import tickService from '../Assets/images/tickService.svg';
import eventsBackground from '../Assets/images/eventsBackground.svg';
import event_1_Image from '../Assets/images/event_1.svg';
import event_2_Image from '../Assets/images/event_2.svg';
import { useNavigate } from 'react-router-dom';

export default function SectionEvents() {
    const navigate = useNavigate();

    return (
        <section class="SectionEvents relative relative overflow-hidden py-10">
            <div className="container mx-auto p-2">
                <div className="flex w-full justify-center py-8 px-6">
                    <div class="relative overflow-hidden cursor-default">
                        <p class="flex justify-center text-5xl font-bold text-left mb-14">
                            <span class="flex text-5xl font-bold text-left text-[#2a3470] mr-2">Corporate </span>
                            <span class="flex text-5xl font-bold text-left text-[#0019b3]"> Events Abroad</span>
                        </p>
                        <div class="flex justify-center items-center gap-10 z-[999] relative">
                            <div
                            class="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 h-[583px] w-[500px] relative overflow-hidden gap-6 p-6 rounded-[36px] bg-white shadow-[0px_6px_16px_0_rgba(42,52,112,0.06)]"
                            >
                            <img
                                src={event_1_Image}
                                draggable="false"
                                class="select-none self-stretch flex-grow-0 flex-shrink-0 h-[272px] rounded-xl object-cover"
                            />
                            <div
                                class="flex flex-col justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-3"
                            >
                                <p class="flex-grow-0 flex-shrink-0 text-[22px] font-bold text-center text-[#2a3470]">
                                Unforgettable Corporate Events Abroad
                                </p>
                                <p
                                class="self-stretch flex-grow-0 flex-shrink-0 w-[452px] text-base text-center text-[#a3a6b8]"
                                >
                                At GASA, we take corporate gatherings to the next level. Whether you're planning an
                                executive retreat, a large-scale conference, or an exciting team-building experience, we
                                ensure every detail is seamless. From logistics to unique experiences, we tailor every
                                event to your company’s needs, so you can focus on what truly matters—building connections
                                and growing your business.
                                </p>
                            </div>
                            </div>
                            <div
                            class="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 w-[500px] relative overflow-hidden gap-6 px-6 pt-6 pb-8 rounded-[36px] bg-white shadow-[0px_6px_16px_0_rgba(42,52,112,0.06)]"
                            >
                            <img
                                src={event_2_Image}
                                draggable="false"
                                class="select-none self-stretch flex-grow-0 flex-shrink-0 h-[272px] rounded-xl object-none"
                            />
                            <div
                                class="flex flex-col justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-3"
                            >
                                <p class="flex-grow-0 flex-shrink-0 text-[22px] font-bold text-center text-[#2a3470]">
                                Success in Global Event Management
                                </p>
                                <p class="self-stretch flex-grow-0 flex-shrink-0 w-[452px] text-base text-center">
                                <span
                                    class="self-stretch flex-grow-0 flex-shrink-0 w-[452px] text-base text-center text-[#a3a6b8]"
                                    >We have successfully organized major corporate events abroad, including large-scale
                                    gatherings like</span
                                ><span
                                    class="self-stretch flex-grow-0 flex-shrink-0 w-[452px] text-base font-bold italic text-center text-[#a3a6b8]"
                                >
                                </span
                                ><span
                                    class="self-stretch flex-grow-0 flex-shrink-0 w-[452px] text-base font-semibold text-center text-[#2a3470]"
                                    >&nbsp;EURO 2024</span
                                ><span
                                    class="self-stretch flex-grow-0 flex-shrink-0 w-[452px] text-base text-center text-[#a3a6b8]"
                                    >. We managed travel and events for five companies, organizing trips for over </span
                                ><span
                                    class="self-stretch flex-grow-0 flex-shrink-0 w-[452px] text-base font-semibold text-center text-[#2a3470]"
                                    >2,000 fans</span
                                ><span
                                    class="self-stretch flex-grow-0 flex-shrink-0 w-[452px] text-base text-center text-[#a3a6b8]"
                                    >, booking </span
                                ><span
                                    class="self-stretch flex-grow-0 flex-shrink-0 w-[452px] text-base font-semibold text-center text-[#2a3470]"
                                    >12 flights</span
                                ><span
                                    class="self-stretch flex-grow-0 flex-shrink-0 w-[452px] text-base text-center text-[#2a3470]"
                                    >,</span
                                ><span
                                    class="self-stretch flex-grow-0 flex-shrink-0 w-[452px] text-base text-center text-[#a3a6b8]"
                                >
                                    and coordinating more than </span
                                ><span
                                    class="self-stretch flex-grow-0 flex-shrink-0 w-[452px] text-base font-semibold text-center text-[#2a3470]"
                                    >15 transfers</span
                                ><span
                                    class="self-stretch flex-grow-0 flex-shrink-0 w-[452px] text-base text-center text-[#2a3470]"
                                    >.</span
                                ><span
                                    class="self-stretch flex-grow-0 flex-shrink-0 w-[452px] text-base text-center text-[#a3a6b8]"
                                >
                                    &nbsp;This experience showcases our ability to handle complex corporate events abroad with
                                    precision, efficiency, and seamless execution.</span
                                >
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>

                    <img
                            src={eventsBackground}
                            draggable="false"
                            alt=""
                            className="z-0 absolute bottom-[0px]"
                        />
                </div>
            </div>
        </section>
    );
};