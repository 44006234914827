import subscribeImg from '../Assets/images/subscribe.svg';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

export default function SectionSubscribe() {
    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState('');
    const [isError, setIsError] = useState(false); // Tracks if input is empty

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        if (e.target.value.trim()) {
            setIsError(false); // Remove error state when user types
        }
    };

    const handleSubmit = (e) => {
        if (!inputValue.trim()) {
            e.preventDefault(); // Prevent form submission
            setIsError(true); // Set error state to apply red border
        }
    };

    return (
        <section className="SectionSubscribe relative overflow-hidden py-10">
            <div className="container mx-auto p-2">
                <div className="flex w-full justify-center py-8 px-6">
                    <div className="relative overflow-hidden cursor-default">
                        <div className="relative">
                            <div className="w-[681px] flex justify-center">
                                <img src={subscribeImg} draggable="false" alt="" className="select-none" />
                            </div>
                            <p className="w-[681px] text-5xl font-bold text-center">
                                <span className="text-[#0019b3]">Subscribe</span>
                                <span className="text-[#2a3470]"> to Our Updates</span>
                            </p>
                            <div className="w-[681px] flex justify-center mt-6 mb-10">
                                <p className="w-[480px] text-base text-center text-[#a3a6b8]">
                                    Be the first to receive tips, exclusive offers, and exciting news straight to your inbox.
                                </p>
                            </div>

                            <div id="mc_embed_shell">
                                <div id="mc_embed_signup">
                                    <form
                                        onSubmit={handleSubmit}
                                        action="https://flygasa.us9.list-manage.com/subscribe/post?u=b9f1f9095fd3690b82297ee70&amp;id=c78bebb396&amp;f_id=00d214e1f0"
                                        method="post"
                                        id="mc-embedded-subscribe-form"
                                        name="mc-embedded-subscribe-form"
                                        target="_self"
                                    >
                                        <div id="mc_embed_signup_scroll">
                                            <div className="mc-field-group">
                                                <label htmlFor="mce-EMAIL"></label>
                                                <input
                                                    type="email"
                                                    value={inputValue}
                                                    onChange={handleInputChange}
                                                    name="EMAIL"
                                                    placeholder="Write your email..."
                                                    className={`required email flex justify-start items-center w-[681px] h-[59px] gap-6 p-4 rounded-2xl bg-white border ${
                                                        isError ? 'border-red-500' : 'border-[#ddd]'
                                                    } text-base text-left text-[#787b8c] outline-none`}
                                                    id="mce-EMAIL"
                                                    
                                                />
                                                {isError && <p className="text-red-500 text-sm mt-1">Email is required.</p>}
                                            </div>
                                            <div id="mce-responses" className="clear">
                                                <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
                                                <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
                                            </div>
                                            <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
                                                <input type="text" name="b_b9f1f9095fd3690b82297ee70_c78bebb396" tabIndex="-1" value="" />
                                            </div>
                                            <div className="clear">
                                                <input
                                                    type="submit"
                                                    name="subscribe"
                                                    id="mc-embedded-subscribe"
                                                    className="button flex justify-center items-center w-[681px] h-[60px] px-[5px] rounded-[200px] bg-[#2a3470] text-lg font-bold text-center uppercase text-white cursor-pointer mt-4"
                                                    value="Subscribe"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
