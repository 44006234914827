import hero from '../Assets/images/hero.svg';
import joinArrow from '../Assets/images/joinarrow.svg';
import tick from '../Assets/images/tick.svg';
import tickCircle from '../Assets/images/tickCircle.svg';
import pegasus from '../Assets/images/pegasus.svg';
import pegasusBackground from '../Assets/images/pegasusBackground.svg';
import { useNavigate } from 'react-router-dom';

export default function SectionPegasus() {
    const navigate = useNavigate();

    return (
        <section class="SectionPegasus bg-white relative relative overflow-hidden py-10">
            <div className="container mx-auto p-2">
                <div className="flex w-full py-20 px-6">
                    <div className="flex-1 gap-5">
                        <div className="flex flex-col justify-start items-start gap-6 mt-16 mb-16">
                            <div className="flex flex-col justify-center items-start overflow-hidden p-3 rounded-xl bg-[#ffd047]">
                                <img
                                    src={pegasus}
                                    draggable="false"
                                    alt=""
                                />
                            </div>
                            <div className="flex flex-col justify-start items-start gap-5">
                                <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative gap-1">
                                    <p className="self-stretch flex-grow-0 flex-shrink-0 w-[372px] text-base font-bold text-left uppercase text-[#0019b3]">
                                        New chapter with pegasus
                                    </p>
                                    <p className="flex-grow-0 flex-shrink-0 text-5xl font-bold text-left text-[#2a3470]">
                                        Fly From Yerevan
                                    </p>
                                </div>
                                <p className="self-stretch flex-grow-0 flex-shrink-0 w-[372px] text-base text-left text-[#787b8c]">
                                    Explore popular destinations with Pegasus Airline’s flights, offering convenience and comfort.
                                </p>
                            </div>
                            <div
                                onClick={() => {
                                    navigate("/join");
                                }} className="JoinButton flex justify-center items-center h-[60px] overflow-hidden mt-4 rounded-[200px]">
                                <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 h-[60px] relative overflow-hidden px-[5px] rounded-[200px] bg-[#2a3470] hover:bg-[#4555B8] transition duration-300 ease-in-out cursor-pointer">
                                    <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 w-[50px] h-[50px] relative overflow-hidden rounded-[200px] bg-white">
                                        <img src={joinArrow} className="relative select-none cursor-pointer" draggable="false" alt="" />
                                    </div>
                                    <span className="flex-grow-0 flex-shrink-0 w-[143px] select-none text-lg font-bold text-center uppercase text-white">
                                        Join US
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex-1">
                        <div className="flex justify-right items-bottom absolute right-[0px] top-[100px]">
                            <img
                                src={pegasusBackground}
                                draggable="false"
                                alt=""
                                className="w-full h-full select-none"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};