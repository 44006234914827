import hero from '../Assets/images/hero.svg';
import joinArrow from '../Assets/images/joinarrow.svg';
import Slider from "./Slider";
import { useNavigate } from 'react-router-dom';

export default function SectionYears() {
    const navigate = useNavigate();

    const dataArray = ["2010","2011","2012","2014","2016","2018","2021","2023","2024"];

    const yearsText = {
        "2010": "Company founded",
        "2011": "20 Employees",
        "2012": "20+ Offices in Georgia",
        "2014": "The largest airline ticketing agency and GSA for Air Arabia in Georgia",
        "2016": "Launched tours to the United Arab Emirates",
        "2018": "Sold 650 000+ tickets",
        "2021": "100+ Employees",
        "2023": "New destination India",
        "2024": "New destination Thailand and Greece"
    }

    return (
        <section class="SectionYears relative relative overflow-hidden">
            <div className="container mx-auto p-2">
                <div className="flex w-full py-20 px-6">
                    <div className="flex w-full">
                        <Slider>
                            {Object.entries(dataArray).map(([key, year]) => {
                            return (
                                <div>
                                    <div className="yearIcon flex justify-center cursor-default"><img className="SearchResultItemImg" src={require('../Assets/years/'+year+'.svg')} alt="" /></div>
                                    <div className="yearTitle select-none flex-grow-0 flex-shrink-0 text-[140px] font-black text-center text-[#2a3470] cursor-default">{year}</div>
                                    <div className="yearText select-none flex-grow-0 flex-shrink-0 text-[27px] font-medium text-center text-[#7178a1] cursor-default">{yearsText[year]}</div>
                                </div>
                            );
                            })}
                        </Slider>
                    </div>

                </div>
            </div>
        </section>
    );
};