import { HashRouter as Router, Route, Routes } from "react-router-dom";
import './Assets/css/App.css';
import Header from "./Components/Header";
import Home from "./Components/Home";
import Join from "./Components/Join";

function App() {
  return (
    <Router>
      <div className="app bg-[#eaf1fd]">
        <Header />
        <Routes>
          <Route path="/" element ={<Home />} />
          <Route path="/join" element ={<Join />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
