export default function Footer() {
  return (
    <section class="SectionFooter relative relative overflow-hidden py-10 bg-[#f5f8fe]">
        <div className="container mx-auto p-2">
            <div className="flex w-full justify-center py-20 px-6">
              <div class="w-[390px]  items-center relative overflow-hidden">
                <p class="text-5xl font-bold text-center cursor-default">
                  <span class="text-5xl font-bold text-center text-[#0019b3]">Contact </span>
                  <span class="text-5xl font-bold text-center text-[#2a3470]">Us</span>
                </p>
                <p class="text-base text-center text-[#787b8c] pt-8 cursor-default">
                  Have questions? Feel free to reach out to us via email—we’re always happy to help.
                </p>
                <div class="flex justify-start items-center justify-center gap-2 pt-6 cursor-default">
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-grow-0 flex-shrink-0 w-5 h-5 relative"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M18.3327 6.33301L10.8577 11.083C10.6004 11.2442 10.3029 11.3297 9.99935 11.3297C9.69575 11.3297 9.39829 11.2442 9.14102 11.083L1.66602 6.33301M3.33268 3.83301H16.666C17.5865 3.83301 18.3327 4.5792 18.3327 5.49967V15.4997C18.3327 16.4201 17.5865 17.1663 16.666 17.1663H3.33268C2.41221 17.1663 1.66602 16.4201 1.66602 15.4997V5.49967C1.66602 4.5792 2.41221 3.83301 3.33268 3.83301Z"
                      stroke="#0019B3"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                  <p class="flex-grow-0 flex-shrink-0 text-lg font-medium text-left text-[#1a2356]">
                    info@gasa.am
                  </p>
                </div>
              </div>
            </div>
        </div>
    </section>
  );
};