import hero from '../Assets/images/hero.svg';
import joinArrow from '../Assets/images/joinarrow.svg';
import { useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import Footer from "./Footer";

export default function SectionHero() {
    const navigate = useNavigate();
    const [formSent, setFormSent] = useState(0);
    
    const [formData, setFormData] = useState({
        accept_terms: "",
        pegasus: "",
        agora: "",
        agencyName: "",
        agencyLegalName: "",
        country: "",
        city: "",
        district: "",
        address: "",
        phoneNumberCode: "",
        phoneNumber: "",
        whatsapp: "",
        whatsappCode: "",
        email: "",
        website: "",
        foundationDate: "",
        iataCertificate: "",
        iataMembershipDate: "",
        authorizedPersonName: "",
        authorizedPersonRole: "",
        authorizedPersonEmail: "",
        staffCount: "",
        agencyActivities: [],
        bankCountry: "",
        iban: "",
        accountOwner: "",
        bankName: "",
        swiftCode: "",
        currency: "",
    });
    
    const [errors, setErrors] = useState({});
    
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === "checkbox") {
          setFormData((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
            agencyActivities: checked
              ? [...prev.agencyActivities, value]
              : prev.agencyActivities.filter((activity) => activity !== value),
          }));
        } else {
          setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const isValidEmail = (email) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    }

    const isNumbersOnly = (str) => {
        return /^[0-9]+(\.[0-9]+)?$/.test(str);
    }

    const isValidIBAN = (iban) => {
        const regex = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/;
        return regex.test(iban);
    }

    const isValidSWIFTCode = (code) => {
        const regex = /^[A-Za-z]{4}[A-Za-z]{2}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?$/;
        return regex.test(code);
    }

    const validateForm = () => {
        const newErrors = {};
        if (!formData.accept_terms) newErrors.accept_terms = "Please accept personal data processing.";
        if (!formData.pegasus && !formData.agora) newErrors.system = "Please select one system to register.";
        if (!formData.agencyName) newErrors.agencyName = "Agency name is required.";
        if (!formData.agencyLegalName) newErrors.agencyLegalName = "Agency name is required.";
        if (!formData.country) newErrors.country = "Please select a country.";
        if (!formData.city) newErrors.city = "City is required.";
        if (!formData.district) newErrors.district = "District field cannot be empty.";
        if (!formData.address) newErrors.address = "Please provide a valid address.";
        if (!formData.phoneNumber || !isNumbersOnly(formData.phoneNumber)) newErrors.phoneNumber = "Enter a valid phone number.";
        if (!formData.whatsapp || !isNumbersOnly(formData.phoneNumber)) newErrors.whatsapp = "Enter a valid whatsapp phone number.";
        if (!formData.email || !isValidEmail(formData.email)) newErrors.email = "Please enter a valid email address.";
        if (!formData.website) newErrors.website = "Enter a valid website URL.";
        if (!formData.foundationDate) newErrors.foundationDate = "Select the foundation date of your agency.";
        if (!formData.iataCertificate) newErrors.iataCertificate = "Please select whether your agency has an IATA Certificate.";
        if (!formData.iataMembershipDate) newErrors.iataMembershipDate = "Please select a valid membership start date.";
        if (!formData.authorizedPersonName) newErrors.authorizedPersonName = "Authorized person’s name is required.";
        if (!formData.authorizedPersonRole) newErrors.authorizedPersonRole = "Please specify the role of the authorized person.";
        if (!formData.authorizedPersonEmail || !isValidEmail(formData.authorizedPersonEmail)) newErrors.authorizedPersonEmail = "Enter a valid email address.";
        if (!formData.staffCount || !isNumbersOnly(formData.staffCount)) newErrors.staffCount = "Please enter a numerical value for staff count.";
        if (formData.agencyActivities.length === 0) newErrors.agencyActivities = "Please select at least one agency activity.";
        if (!formData.bankCountry) newErrors.bankCountry = "Please select the country where the bank account is registered.";
        if (!formData.iban || !isValidIBAN(formData.iban)) newErrors.iban = "Enter a valid IBAN number.";
        if (!formData.accountOwner) newErrors.accountOwner = "Account owner’s name is required.";
        if (!formData.bankName) newErrors.bankName = "Please enter the name of your bank.";
        if (!formData.swiftCode || !isValidSWIFTCode(formData.swiftCode)) newErrors.swiftCode = "Enter a valid SWIFT code.";
        if (!formData.currency) newErrors.currency = "Please select a currency.";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = validateForm();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        try {
            const response = await fetch('https://api.gasa.am/users', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                setFormSent(1);
            } else {
                setFormSent(0);
            }
        } catch (error) {
            setFormSent(0);
        }
    };

    const [activeStep, setActiveStep] = useState(0);
  
    // Create refs for each step content section
    const stepRefs = [
      useRef(null),
      useRef(null),
      useRef(null),
      useRef(null),
    ];

    const handleStepClick = (stepIndex) => {
      setActiveStep(stepIndex);
      const targetElement = stepRefs[stepIndex].current;
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    };


    useEffect(() => {
        // Set up IntersectionObserver
        const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const stepIndex = parseInt(entry.target.id.split("-")[1], 10);
                setActiveStep(stepIndex); // Update active step when section is in view
            }
            });
        },
        { threshold: 0.5 } // Trigger when 50% of the section is visible
        );

        // Observe all the section refs
        stepRefs.forEach((ref) => {
        if (ref.current) {
            observer.observe(ref.current);
        }
        });

        return () => {
        // Clean up observer on unmount
        observer.disconnect();
        };
    }, []);


    return (
        <div className="SectionJoinParent">
        <section className="SectionJoin relative relative overflow-hidden mt-10">
        <div className="container mx-auto p-2">
        <div className="w-full flex mx-auto p-8 bg-white rounded-3xl shadow-lg mb-10 pb-10 h-[calc(100vh-195px)] overflow-y-auto scrollbar">
          {/* Progress Sidebar */}
          <div className="pt-12 w-64 space-y-6 sticky top-0">
            <ProgressStep
                label="Agency information"
                active={activeStep === 0}
                onClick={() => handleStepClick(0)}
            />
            <ProgressStep
                label="Authorized person information"
                active={activeStep === 1}
                onClick={() => handleStepClick(1)}
            />
            <ProgressStep
                label="Agency activities"
                active={activeStep === 2}
                onClick={() => handleStepClick(2)}
            />
            <ProgressStep
                label="Bank account information"
                active={activeStep === 3}
                onClick={() => handleStepClick(3)}
            />
          </div>
    
          {/* Main Form Content */}
          <form onSubmit={handleSubmit} className="space-y-8 ml-28">
            {formSent == 0 ? (<p className="text-[32px] font-bold text-left">
                <span className="text-[#2a3470]">Join Us and </span>
                <span className="text-[#0019b3]">Become a Partner</span>
            </p>) : (<p className="text-[32px] font-bold text-left">
                <span className="text-[#2a3470]">Summary</span>
            </p>)}
            
            {/* System Selection */}
            {formSent == 0 ? (<div>
            <label className="block text-lg font-semibold mb-4 text-[#2A3470]">
                Please select the system you want to register in:
            </label>
            <div className="flex gap-8 pagasa">
                <label key="pegasus" className="flex items-center gap-3">
                    <input
                    type="checkbox"
                    name="pegasus"
                    checked={formData.pegasus}
                    onChange={handleChange}
                    className="w-6 h-6 border-2 border-blue-200 rounded-lg"
                    />
                    <span className="text-[#2A3470]">Pegasus</span>
                </label>
                <label key="agora" className="flex items-center gap-3">
                    <input
                    type="checkbox"
                    name="agora"
                    checked={formData.agora}
                    onChange={handleChange}
                    className="w-6 h-6 border-2 border-blue-200 rounded-lg"
                    />
                    <span className="text-[#2A3470]">Booking Agora</span>
                </label>
            </div>
            {errors.system && <p className="text-sm text-red-600 mt-2">{errors.system}</p>}
            </div>) : ""}

            {/* Agency Information */}
            <div id="step-0" ref={stepRefs[0]}>
            {formSent == 0 ? (
                <div>
                    <h2 className="text-lg font-semibold text-[#2A3470] mb-5">Agency Information</h2>
                    <div className="grid grid-cols-2 gap-5">
                        <InputField
                        label="Agency Name"
                        name="agencyName"
                        value={formData.agencyName}
                        onChange={handleChange}
                        error={errors.agencyName}
                        />
                        <InputField
                        label="Agency Legal Name"
                        name="agencyLegalName"
                        value={formData.agencyLegalName}
                        onChange={handleChange}
                        error={errors.agencyLegalName}
                        />
                        <SelectField
                        label="Country"
                        name="country"
                        onChange={handleChange}
                        options={countries}
                        value={formData.country}
                        error={errors.country}
                        />
                        <SelectField
                        label="City"
                        name="city"
                        onChange={handleChange}
                        options={cities}
                        value={formData.city}
                        error={errors.city}
                        />
                    </div>
                    <div className="grid grid-cols-1 gap-5 mt-5">
                        <InputField
                        label="District"
                        name="district"
                        value={formData.district}
                        onChange={handleChange}
                        error={errors.district}
                        />
                        <InputField
                        label="Address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        error={errors.address}
                        />
                    </div>
                    <div className="grid grid-cols-[1fr_4fr] gap-5 mt-5">
                        <SelectField
                        label=""
                        name="phoneNumberCode"
                        onChange={handleChange}
                        options={phoneNumberCodes}
                        value={formData.phoneNumberCode}
                        error={errors.phoneNumberCode}
                        />
                        <InputField
                        label="Phone Number"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        error={errors.phoneNumber}
                        />
                    </div>
                    <div className="grid grid-cols-[1fr_4fr] gap-5 mt-5">
                        <SelectField
                        label=""
                        name="whatsappCode"
                        onChange={handleChange}
                        options={phoneNumberCodes}
                        value={formData.whatsappCode}
                        error={errors.whatsappCode}
                        />
                        <InputField
                        label="Whatsapp Phone Number"
                        name="whatsapp"
                        value={formData.whatsapp}
                        onChange={handleChange}
                        error={errors.whatsapp}
                        />
                    </div>
                    <div className="grid grid-cols-1 gap-5 mt-5">
                        <InputField
                        label="Agency Contact Email Address"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        error={errors.email}
                        />
                        <InputField
                        label="Agency Web Address"
                        name="website"
                        value={formData.website}
                        onChange={handleChange}
                        error={errors.website}
                        />
                    </div>
                    <div className="grid grid-cols-3 gap-5 mt-5">
                        <DatePicker
                        label="Choose Foundation Date"
                        name="foundationDate"
                        value={formData.foundationDate}
                        customClass={"w-[260px]"}
                        onChange={handleChange}
                        error={errors.foundationDate}
                        />
                    </div>
                </div>) :
                (
                    <div>
                        <div className="flex flex-col justify-start items-start w-[515px] gap-8">
                            <div className="flex justify-between items-center w-full">
                                <div className="flex flex-col w-[232px] gap-5">
                                <p className="text-base font-semibold text-left text-[#2a3470]">Agency Information</p>
                                <p className="text-base text-left text-[#787b8c]">Agency Name</p>
                                <p className="text-base text-left text-[#787b8c]">Agency Legal Name</p>
                                <p className="text-base text-left text-[#787b8c]">Country</p>
                                <p className="text-base text-left text-[#787b8c]">City</p>
                                <p className="text-base text-left text-[#787b8c]">District</p>
                                <p className="text-base text-left text-[#787b8c]">Address</p>
                                <p className="text-base text-left text-[#787b8c]">Phone Number</p>
                                <p className="text-base text-left text-[#787b8c]">WhatsApp Phone Number</p>
                                <p className="text-base text-left text-[#787b8c]">Agency Contact Email Address</p>
                                <p className="text-base text-left text-[#787b8c]">Agency Web Address</p>
                                <p className="text-base text-left text-[#787b8c]">Foundation Date</p>
                                <p className="text-base text-left text-[#787b8c]">IATA Certification</p>
                                <p className="text-base text-left text-[#787b8c]">IATA Membership Date</p>
                                </div>
                                <div className="flex flex-col w-[194px] gap-5">
                                <p className="opacity-0 text-base font-semibold text-left text-[#2a3470]">Agency Information</p>
                                <p className="text-base font-medium text-left text-[#2a3470]"><span className="opacity-0 tx-mb-name">Agency Name</span>{formData.agencyName}</p>
                                <p className="text-base font-medium text-left text-[#2a3470]"><span className="opacity-0 tx-mb-name">Agency Legal Name</span>{formData.agencyLegalName}</p>
                                <p className="text-base font-medium text-left text-[#2a3470]"><span className="opacity-0 tx-mb-name">Country</span>{formData.country}</p>
                                <p className="text-base font-medium text-left text-[#2a3470]"><span className="opacity-0 tx-mb-name">City</span>{formData.city}</p>
                                <p className="text-base font-medium text-left text-[#2a3470]"><span className="opacity-0 tx-mb-name">District</span>{formData.district}</p>
                                <p className="text-base font-medium text-left text-[#2a3470]"><span className="opacity-0 tx-mb-name">Address</span>{formData.address}</p>
                                <p className="text-base font-medium text-left text-[#2a3470]"><span className="opacity-0 tx-mb-name">Phone Number</span>{formData.phoneNumberCode} {formData.phoneNumber}</p>
                                <p className="text-base font-medium text-left text-[#2a3470]"><span className="opacity-0 tx-mb-name">WhatsApp Phone Number</span>{formData.whatsappCode} {formData.whatsapp}</p>
                                <p className="text-base font-medium text-left text-[#2a3470]"><span className="opacity-0 tx-mb-name">Agency Contact Email Address</span>{formData.email}</p>
                                <p className="text-base font-medium text-left text-[#2a3470]"><span className="opacity-0 tx-mb-name">Agency Web Address</span>{formData.website}</p>
                                <p className="text-base font-medium text-left text-[#2a3470]"><span className="opacity-0 tx-mb-name">Foundation Date</span>{formData.foundationDate}</p>
                                <p className="text-base font-medium text-left text-[#2a3470]"><span className="opacity-0 tx-mb-name">IATA Certification</span>{formData.iataCertificate}</p>
                                <p className="text-base font-medium text-left text-[#2a3470]"><span className="opacity-0 tx-mb-name">IATA Membership Date</span>{formData.iataMembershipDate}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                }
            </div>

            {/* IATA Certificate */}
            {formSent == 0 ? (
                <div>
                    <div>
                        <label className="block text-lg font-semibold text-[#2A3470] mb-4">
                            Is there an IATA Certificate? Please select:
                        </label>
                        <div className="flex gap-8 mb-10">
                            {["Yes", "No"].map((option) => (
                            <label key={option} className="flex items-center gap-3">
                                <input
                                type="radio"
                                name="iataCertificate"
                                value={option}
                                onChange={handleChange}
                                className="w-6 h-6 border-2 border-blue-200 rounded-lg"
                                />
                                <span className="text-[#2A3470]">{option}</span>
                            </label>
                            ))}
                        </div>
                        {errors.iataCertificate && <p className="text-sm text-red-600 mt-2">{errors.iataCertificate}</p>}
                    </div>

                    {/* IATA Membership Date */}
                    <div>
                        <label className="block text-lg font-semibold text-[#2A3470] mb-4">
                            Please select IATA membership start date
                        </label>
                        <div class="grid grid-cols-3 gap-5 mt-5">
                                <DatePicker
                                    label="Select Membership Date"
                                    name="iataMembershipDate"
                                    onChange={handleChange}
                                    value={formData.iataMembershipDate}
                                    error={errors.iataMembershipDate}
                                    customclassName="w-[260px]"
                                    />
                        </div>
                    </div>
                </div>
            ) : ""}
    
            {/* Authorized Person Section */}
            <div id="step-1" ref={stepRefs[1]}>
            {formSent == 0 ? (
                <Section title="Authorized Person Information">
                <div className="grid grid-cols-2 gap-5">
                    <InputField
                    label="Authorized Person (Full name)"
                    name="authorizedPersonName"
                    onChange={handleChange}
                    value={formData.authorizedPersonName}
                    error={errors.authorizedPersonName}
                    />
                    <InputField
                    label="Authorized Person Role"
                    name="authorizedPersonRole"
                    onChange={handleChange}
                    value={formData.authorizedPersonRole}
                    error={errors.authorizedPersonRole}
                    />
                </div>
                <div className="grid grid-cols-1 gap-5">
                    <InputField
                    label="Email Address of Authorized Person"
                    name="authorizedPersonEmail"
                    type="email"
                    onChange={handleChange}
                    value={formData.authorizedPersonEmail}
                    error={errors.authorizedPersonEmail}
                    />
                </div>
                <div className="grid grid-cols-1 gap-5">
                    <InputField
                    label="Staff Count (Please write a number)"
                    name="staffCount"
                    type="number"
                    onChange={handleChange}
                    value={formData.staffCount}
                    error={errors.staffCount}
                    />
                </div>
                </Section>) : (
                    <div>
                        <div className="flex flex-col justify-start items-start w-[515px] gap-8">
                            <div className="flex justify-between items-center w-full">
                                <div className="flex flex-col w-[232px] gap-5">
                                    <p className="text-base font-semibold text-left text-[#2a3470]">Authorized Person Information</p>
                                    <p className="text-base text-left text-[#787b8c]">Authorized Person Full Name</p>
                                    <p className="text-base text-left text-[#787b8c]">Authorized Person Role</p>
                                    <p className="text-base text-left text-[#787b8c]">Email Address of Authorized Person</p>
                                    <p className="text-base text-left text-[#787b8c]">Staff Count</p>
                                </div>
                                <div className="flex flex-col w-[194px] gap-5">
                                    <p className="opacity-0 text-base font-semibold text-left text-[#2a3470]">Authorized Person Information</p>
                                    <p className="text-base font-medium text-left text-[#2a3470]"><span className="opacity-0 tx-mb-name">Authorized Person Full Name</span>{formData.authorizedPersonName}</p>
                                    <p className="text-base font-medium text-left text-[#2a3470]"><span className="opacity-0 tx-mb-name">Authorized Person Role</span>{formData.authorizedPersonRole}</p>
                                    <p className="text-base font-medium text-left text-[#2a3470]"><span className="opacity-0 tx-mb-name">Email Address of Authorized Person</span>{formData.authorizedPersonEmail}</p>
                                    <p className="text-base font-medium text-left text-[#2a3470]"><span className="opacity-0 tx-mb-name">Staff Count</span>{formData.staffCount}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                }
            </div>
    
            {/* Agency Activities */}
            <div id="step-2" ref={stepRefs[2]}>
            {formSent == 0 ? (
                <Section title="Agency Activities">
                <div className="flex flex-wrap gap-8 max-w-lg">
                    {["Incoming", "Outgoing", "Package Tours", "Health Tourism", "Event Planner"].map((activity) => (
                    <label key={activity} className="flex items-center gap-3">
                        <input
                        type="checkbox"
                        name="agencyActivities"
                        value={activity}
                        checked={formData.agencyActivities.includes(activity)}
                        onChange={handleChange}
                        className="w-6 h-6 border-2 border-blue-200 rounded-lg"
                        />
                        <span className="text-[#2A3470]">{activity}</span>
                    </label>
                    ))}
                </div>
                {errors.agencyActivities && <p className="text-sm text-red-600">{errors.agencyActivities}</p>}
                </Section>) : (
                    <div>
                        <div className="flex flex-col justify-start items-start w-[515px] gap-8">
                            <div className="flex justify-between items-center w-full">
                                <div className="flex flex-col w-[232px] gap-5">
                                    <p className="text-base font-semibold text-left text-[#2a3470]">Agency Activities</p>
                                    <p className="text-base text-left text-[#787b8c]">Agency Activity</p>
                                </div>
                                <div className="flex flex-col w-[194px] gap-5">
                                    <p className="opacity-0 text-base font-semibold text-left text-[#2a3470]">Agency Activities</p>
                                    <p className="text-base font-medium text-left text-[#2a3470]"><span className="opacity-0 tx-mb-name">Agency Activity</span>{formData.agencyActivities.filter(activity => activity.toLowerCase() !== 'on').join(', ')}</p>
                                </div>
                            </div>
                        </div>
                    </div> 
                )
            }
            </div>
    
            {/* Bank Account Section */}
            <div id="step-3" ref={stepRefs[3]}>
            {formSent == 0 ? (
                <Section title="Bank Account Information">
                <div className="grid grid-cols-2 gap-5">
                    <SelectField
                    label="Country"
                    name="bankCountry"
                    options={countries}
                    onChange={handleChange}
                    value={formData.bankCountry}
                    error={errors.bankCountry}
                    />
                </div>
                <div className="grid grid-cols-2 gap-5">
                    <InputField
                    label="IBAN"
                    name="iban"
                    onChange={handleChange}
                    value={formData.iban}
                    error={errors.iban}
                    />
                    <InputField
                    label="Account Owner"
                    name="accountOwner"
                    onChange={handleChange}
                    value={formData.accountOwner}
                    error={errors.accountOwner}
                    />
                    <InputField
                    label="Bank Name"
                    name="bankName"
                    onChange={handleChange}
                    value={formData.bankName}
                    error={errors.bankName}
                    />
                    <InputField
                    label="SWIFT Code"
                    name="swiftCode"
                    onChange={handleChange}
                    value={formData.swiftCode}
                    error={errors.swiftCode}
                    />
                    <SelectField
                    label="Currency"
                    name="currency"
                    options={currencies}
                    onChange={handleChange}
                    value={formData.currency}
                    error={errors.currency}
                    />

                </div>
                <svg
                width="100%"
                height="1"
                viewBox="0 0 552 1"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="flex-grow-0 flex-shrink-0"
                preserveAspectRatio="none"
                >
                <line y1="0.5" x2="552" y2="0.5" stroke="#DDDDDD"></line>
                </svg>
                <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-3">
                    <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 gap-2.5 py-1">
                        <label key="accept_terms" className="flex items-center gap-3">
                            <input
                            type="checkbox"
                            name="accept_terms"
                            checked={formData.accept_terms}
                            onChange={handleChange}
                            className="w-6 h-6 border-2 border-blue-200 rounded-lg"
                            />
                        </label>
                    </div>
                    <p className="flex-grow w-full opacity-80 text-sm text-left text-[#787b8c]">
                        By checking this graph, I Express my informed and irrevocable consent to the processing of
                        mentioned my personal data for the purpose of providing tourist services in accordance with
                        personal data protection regulation and, if necessary, the mentioned purpose to transfer the
                        said data to public or private institutions/organizations of Georgia and other states. I am
                        aware that the processed data will be stored for the period necessary for the above-mentioned
                        purposes of data processing.
                    </p>
                </div>

                {errors.accept_terms && <p className="text-sm text-red-600 mt-2">{errors.accept_terms}</p>}
                </Section>) : (
                    <div>
                        <div className="flex flex-col justify-start items-start w-[515px] gap-8">
                            <div className="flex justify-between items-center w-full">
                                <div className="flex flex-col w-[232px] gap-5">
                                    <p className="text-base font-semibold text-left text-[#2a3470]">Bank Account Information</p>
                                    <p className="text-base text-left text-[#787b8c]">Country</p>
                                    <p className="text-base text-left text-[#787b8c]">IBAN</p>
                                    <p className="text-base text-left text-[#787b8c]">Account Owner</p>
                                    <p className="text-base text-left text-[#787b8c]">Bank Name</p>
                                    <p className="text-base text-left text-[#787b8c]">Swift Code</p>
                                    <p className="text-base text-left text-[#787b8c]">Currency</p>
                                </div>
                                <div className="flex flex-col w-[194px] gap-5">
                                    <p className="opacity-0 text-base font-semibold text-left text-[#2a3470]">Bank Account Information</p>
                                    <p className="text-base font-semibold text-left text-[#2a3470]"><span className="opacity-0 tx-mb-name">Country</span>{formData.bankCountry}</p>
                                    <p className="text-base font-semibold text-left text-[#2a3470]"><span className="opacity-0 tx-mb-name">IBAN</span>{formData.iban}</p>
                                    <p className="text-base font-semibold text-left text-[#2a3470]"><span className="opacity-0 tx-mb-name">Account Owner</span>{formData.accountOwner}</p>
                                    <p className="text-base font-semibold text-left text-[#2a3470]"><span className="opacity-0 tx-mb-name">Bank Name</span>{formData.bankName}</p>
                                    <p className="text-base font-semibold text-left text-[#2a3470]"><span className="opacity-0 tx-mb-name">Swift Code</span>{formData.swiftCode}</p>
                                    <p className="text-base font-semibold text-left text-[#2a3470]"><span className="opacity-0 tx-mb-name">Currency</span>{formData.currency}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            </div>
                    
            <a href="/"
            className={`flex items-center justify-center h-[60px] w-full rounded-[200px] bg-[#2a3470] text-lg font-bold text-white uppercase ${formSent == 1 ? "show" : "hide"}`}>Back to homepage</a>
            
            <button type="submit"
            className={`h-[60px] w-full rounded-[200px] bg-[#2a3470] text-lg font-bold text-white uppercase ${formSent == 0 ? "show" : "hide"}`}>Submit</button>

            <div className="h-[10px]"></div>
          </form>
        </div>
    </div>
    </section>
    <div className="h-[40px] isFooter"></div>
    <Footer />
    </div>
    );

}


// Reusable Input Field Component
const InputField = ({ label, name, value, onChange, error }) => (
    <div className="flex flex-col gap-1.5">
      <input
        type="text"
        name={name}
        value={value}
        placeholder={label}
        onChange={onChange}
        className={`pl-6 pr-6 pt-3 pb-3 text-[#787B8C] border rounded-[16px] ${error ? "border-red-600" : "border-gray-300"}`}
      />
      {error && <p className="text-sm text-red-600">{error}</p>}
    </div>
);
  
// Reusable Date Picker Component
const DatePicker = ({ label, name, value, customClass, onChange, error }) => (
    <div className="relative flex flex-col gap-1.5">
      <label
        className={`absolute left-6 top-3.5 text-gray-400 transition-all z-0 ${
          value ? "hidden" : "text-[16px]"
        }`}
      >
        {label}
      </label>
      <input
        type="date"
        name={name}
        value={value}
        onChange={onChange}
        className={`pt-3 pb-3 pl-6 pr-6 border ${value ? "" : "indent-[-9999px]"} text-[#2A3470] ${customClass} bg-transparent z-[9999] rounded-[16px] ${
          error ? "border-red-600" : "border-gray-300"
        }`}
      />
      {error && <p className="text-sm text-red-600">{error}</p>}
    </div>
);

// New Components
const ProgressStep = ({ label, active = false, onClick }) => (
    <div
      className="inline-block gap-1 cursor-pointer select-none w-inherit"
      onClick={onClick}
    >
      <p className={`text-base ${active ? "text-blue-800 font-semibold" : "text-gray-500"}`}>
        {label}
      </p>
      <div className={`h-0.5 rounded-sm ${active ? "bg-blue-800" : "bg-transparent"}`}></div>
    </div>
);

const Section = ({ title, children }) => (
    <div className="space-y-5">
        <h2 className="text-lg font-semibold text-[#2A3470]">{title}</h2>
        {children}
    </div>
);

const SelectField = ({ label, name, options, value, error, onChange }) => (
    <div className="flex flex-col gap-1.5">
        <select
        name={name}
        value={value}
        placeholder={label}
        onChange={onChange}
        className={`pl-6 pr-6 pt-3 pb-3 text-[#787B8C] border rounded-[16px] appearance-none ${error ? "border-red-600" : "border-gray-300"}`}
        >
        {label !== "" ? (<option value="">{label}</option>) : ''}
        {options.map((option) => (
            <option key={option} value={option}>{option}</option>
        ))}
        </select>
        {error && <p className="text-sm text-red-600">{error}</p>}
    </div>
);

// Sample data
const countries = ["Armenia"];
const cities = ["Yerevan", "Gyumri", "Vanadzor", "Hrazdan", "Abovyan", "Kapan", "Goris", "Armavir"];
const currencies = ["AMD", "EUR", "USD", "GEL"];
const phoneNumberCodes = ["+374 33", "+374 41", "+374 43", "+374 44", "+374 49", "+374 55", "+374 77", "+374 91",
                          "+374 93", "+374 94", "+374 95", "+374 96", "+374 97", "+374 98", "+374 99"];