import logo from '../Assets/images/logo.svg';
import comingSoon from '../Assets/images/comingsoon.svg';
import agencyArrow from '../Assets/images/agencyarrow.svg';
import joinArrow from '../Assets/images/joinarrow.svg';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Header() {
    const navigate = useNavigate();
    const [toggleMenu, setToggleMenu] = useState(0);

    return (
        <div className="SectionHeader relative bg-[linear-gradient(111.08deg, rgba(255,255,255,0.35) 28.11%, rgba(255,255,255,0.35) 90.47%)] border-b border-[#d2d9fe] backdrop-blur-[114px]" >
            <div className="container mx-auto p-2">
                <div className="flex w-full">
                    <div class="Logo flex-1 flex justify-left items-center">
                        <img src={logo} className="relative select-none cursor-pointer" draggable="false" alt=""
                            onClick={() => {
                                navigate("/");
                            }} />
                    </div>
                    <div className="menuBurger" onClick={() => {
                            setToggleMenu(toggleMenu == 1 ? 0 : 1);
                        }}>
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6 relative"
                            preserveAspectRatio="none"
                            >
                            <path
                                d="M4 12H20M4 6H20M4 18H20"
                                stroke="#2A3470"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            ></path>
                        </svg>
                    </div>
                    <div className="flex justify-end items-end gap-5">
                        <div className={`ButtonsDisplay flex justify-end items-end gap-5 hideMobile ${toggleMenu === 1 ? "menuBlock" : ""}`}>
                            <div className="flex flex-col justify-center items-start self-stretch flex-grow-0 flex-shrink-0 gap-2.5">
                                <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-[5px] px-[19px] cursor-default">
                                    <div className="animate-ping"><img src={comingSoon} className="relative select-none cursor-default" draggable="false" alt="" /></div>
                                    <span className="flex-grow-0 flex-shrink-0 text-xs font-medium text-left uppercase text-[#1736f5]">
                                        Coming soon
                                    </span>
                                </div>
                                <div className="menuBlockItem flex justify-center items-center flex-grow-0 flex-shrink-0 h-14 relative overflow-hidden pl-[3px] pr-2 py-[3px] rounded-[200px] bg-gray-900 hover:bg-[#4555B8] transition duration-300 ease-in-out cursor-pointer">
                                    <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 w-[50px] h-[50px] relative overflow-hidden rounded-[200px] bg-white">
                                        <img src={agencyArrow} className="relative select-none cursor-pointer" draggable="false" alt="" />
                                    </div>
                                    <span className="flex-grow-0 flex-shrink-0 w-[143px] text-base font-bold text-center uppercase text-white">
                                        Agency log in
                                    </span>
                                </div>
                            </div>
                            <div
                            onClick={() => {
                                navigate("/join");
                                setToggleMenu(0);
                            }} className="menuBlockItem flex justify-start items-center flex-grow-0 flex-shrink-0 w-[174px] h-14 relative overflow-hidden p-[3px] rounded-[200px] bg-[#2a3470] cursor-pointer">
                                <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 w-[50px] h-[50px] relative overflow-hidden rounded-[200px] bg-white">
                                    <img src={joinArrow} className="relative select-none cursor-pointer" draggable="false" alt="" />
                                </div>
                                <span className="flex-grow-0 flex-shrink-0 w-[114px] text-base font-bold text-center uppercase text-white">
                                    Join US
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};