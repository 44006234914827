import hero from '../Assets/images/hero.svg';
import tickService from '../Assets/images/tickService.svg';
import servicesBackground from '../Assets/images/servicesBackground.svg';
import { useNavigate } from 'react-router-dom';

export default function SectionServices() {
    const navigate = useNavigate();

    return (
        <section class="SectionServices relative relative overflow-hidden py-10">
            <div className="container mx-auto p-2">
                <div className="flex w-full py-20 px-6">
                    <div className="flex-1 gap-5">
                        <div className="flex flex-col justify-start items-start gap-6 mt-16 mb-16">
                            <div className="w-[448px] h-[358px]">
                                <div className="flex flex-col justify-start items-start w-[448px] gap-5">
                                    <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative gap-1">
                                        <p className="self-stretch flex-grow-0 flex-shrink-0 w-[349px] text-base font-bold text-left uppercase text-[#0019b3]">
                                            services at gasa
                                        </p>
                                        <p className="flex-grow-0 flex-shrink-0 text-5xl font-bold text-left text-[#2a3470]">
                                            Travel Solutions
                                        </p>
                                    </div>
                                    <p className="self-stretch flex-grow-0 flex-shrink-0 w-[448px] text-base text-left text-[#787b8c]">
                                        From air tickets and tour packages to corporate travel and event planning GASA offers it all
                                        with exceptional service.
                                    </p>
                                </div>

                                <div className="flex justify-between items-start w-[391px] h-[123px] mt-10">
                                    <div className="flex flex-col justify-between items-start self-stretch flex-grow-0 flex-shrink-0 w-[136px]">
                                        <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2">
                                            <img
                                                src={tickService}
                                                draggable="false"
                                                alt=""
                                                className="select-none"
                                            />
                                            <p className="flex-grow-0 flex-shrink-0 text-base text-left text-[#1e223b]">Flight tickets</p>
                                        </div>
                                        <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
                                            <img
                                                src={tickService}
                                                draggable="false"
                                                alt=""
                                                className="w-full h-full select-none"
                                            />
                                            <p className="flex-grow-0 flex-shrink-0 text-base text-left text-[#1e223b]">Hotels</p>
                                        </div>
                                        <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
                                            <img
                                                src={tickService}
                                                draggable="false"
                                                alt=""
                                                className="w-full h-full select-none"
                                            />
                                            <p className="flex-grow-0 flex-shrink-0 text-base text-left text-[#1e223b]">Transfers</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-between items-start self-stretch flex-grow-0 flex-shrink-0 w-[177px]">
                                        <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
                                            <img
                                                src={tickService}
                                                draggable="false"
                                                alt=""
                                                className="w-full h-full select-none"
                                            />
                                            <p className="flex-grow-0 flex-shrink-0 text-base text-left text-[#1e223b]">Insurance</p>
                                        </div>
                                        <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
                                            <img
                                                src={tickService}
                                                draggable="false"
                                                alt=""
                                                className="w-full h-full select-none"
                                            />
                                            <p className="flex-grow-0 flex-shrink-0 text-base text-left text-[#1e223b]">Tour packages</p>
                                        </div>
                                        <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2">
                                            <img
                                                src={tickService}
                                                draggable="false"
                                                alt=""
                                                className="select-none"
                                            />
                                            <p className="flex-grow-0 flex-shrink-0 text-base text-left text-[#1e223b]">
                                                Train / Bus tickets
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="flex-1">
                        <div className="flex justify-right items-bottom absolute right-[0px] top-[100px]">
                            <img
                                src={servicesBackground}
                                draggable="false"
                                alt=""
                                className="w-full h-full select-none"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};