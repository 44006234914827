import SectionHero from "./SectionHero";
import SectionYears from "./SectionYears";
import SectionPegasus from "./SectionPegasus";
import SectionServices from "./SectionServices";
import SectionServicesList from "./SectionServicesList";
import SectionEvents from "./SectionEvents";
import SectionSubscribe from "./SectionSubscribe";
import Footer from "./Footer";

const Home = (props) => {
  return (
    <div>
      <SectionHero />
      <SectionYears />
      <SectionPegasus />
      <SectionServices />
      <SectionServicesList />
      <SectionEvents />
      <SectionSubscribe />
      <Footer />
    </div>
  );
};

export default Home;