import hero from '../Assets/images/hero.svg';
import tickService from '../Assets/images/tickService.svg';
import servicesAllBackground from '../Assets/images/servicesAllBackground.svg';
import { useNavigate } from 'react-router-dom';

export default function SectionServicesList() {
    const navigate = useNavigate();

    return (
        <section className="SectionServicesList relative relative overflow-hidden bg-white">
            <div className="container mx-auto">
                <div className="flex w-full px-6 relative">
                    <div className="flex-1 gap-5 z-[999] relative">
                        <div className="flex flex-col justify-start items-start gap-6 mt-16 mb-16">
                            <div className="container mx-auto">

                                <p className="mt-20 mb-8 flex justify-center text-5xl font-bold text-left">
                                    <span className="text-5xl font-bold text-left text-[#2a3470] mr-2">Our </span><span className="text-5xl font-bold text-left text-[#0019b3]"> Comprehensive Services</span>
                                </p>
                                <div className="flex flex-wrap justify-center items-center py-10 mb-20 gap-4 px-5">
                                    <div className="hover:bg-[#f3f7fe] transition duration-300 ease-in-out cursor-pointer flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2.5 px-4 py-3 rounded-[100.14px] bg-white border border-[#d2d9fe]">
                                    <p className="flex-grow-0 flex-shrink-0 text-[18.024324417114258px] text-center text-[#787b8c]">
                                        Lowest fare
                                    </p>
                                    <svg
                                        width="25"
                                        height="25"
                                        viewBox="0 0 25 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="flex-grow-0 flex-shrink-0 w-[24.03px] h-[24.03px] relative"
                                        preserveAspectRatio="none"
                                    >
                                        <path
                                        d="M16.7782 8.54326H10.7701C10.2389 8.54326 9.72954 8.75426 9.35396 9.12984C8.97838 9.50542 8.76739 10.0148 8.76739 10.546C8.76739 11.0771 8.97838 11.5865 9.35396 11.9621C9.72954 12.3377 10.2389 12.5487 10.7701 12.5487H14.7755C15.3066 12.5487 15.816 12.7597 16.1916 13.1352C16.5672 13.5108 16.7782 14.0202 16.7782 14.5514C16.7782 15.0825 16.5672 15.5919 16.1916 15.9675C15.816 16.3431 15.3066 16.5541 14.7755 16.5541H8.76739M12.7728 18.5568V6.54056M22.7863 12.5487C22.7863 18.079 18.3031 22.5622 12.7728 22.5622C7.24248 22.5622 2.75928 18.079 2.75928 12.5487C2.75928 7.01836 7.24248 2.53516 12.7728 2.53516C18.3031 2.53516 22.7863 7.01836 22.7863 12.5487Z"
                                        stroke="#0019B3"
                                        stroke-width="2.0027"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        ></path>
                                    </svg>
                                    </div>
                                    <div className="hover:bg-[#f3f7fe] transition duration-300 ease-in-out cursor-pointer flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2.5 px-4 py-3 rounded-[100.14px] bg-white border border-[#d2d9fe]">
                                        <p className="flex-grow-0 flex-shrink-0 text-[18.024324417114258px] text-center text-[#787b8c]">
                                            BolBol Points
                                        </p>
                                        <svg
                                            width="25"
                                            height="25"
                                            viewBox="0 0 25 25"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="flex-grow-0 flex-shrink-0 w-[24.03px] h-[24.03px] relative"
                                            preserveAspectRatio="none"
                                        >
                                            <path
                                            d="M18.9032 10.9165C19.8498 11.2694 20.6922 11.8553 21.3523 12.62C22.0125 13.3847 22.4691 14.3035 22.6801 15.2914C22.891 16.2794 22.8495 17.3046 22.5592 18.2722C22.269 19.2399 21.7394 20.1187 21.0195 20.8275C20.2996 21.5362 19.4127 22.052 18.4406 22.3271C17.4686 22.6022 16.4428 22.6278 15.4583 22.4014C14.4737 22.1751 13.5622 21.7041 12.8078 21.0321C12.0535 20.3601 11.4809 19.5088 11.1428 18.5568M7.79826 6.54056H8.79961V10.546M17.5214 14.4312L18.2223 15.1422L15.3985 17.966M14.8077 8.54326C14.8077 11.8615 12.1178 14.5514 8.79961 14.5514C5.48143 14.5514 2.7915 11.8615 2.7915 8.54326C2.7915 5.22508 5.48143 2.53516 8.79961 2.53516C12.1178 2.53516 14.8077 5.22508 14.8077 8.54326Z"
                                            stroke="#0019B3"
                                            stroke-width="2.0027"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            ></path>
                                        </svg>
                                    </div>
                                    <div
                                    className="hover:bg-[#f3f7fe] transition duration-300 ease-in-out cursor-pointer flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2.5 px-4 py-3 rounded-[100.14px] bg-white border border-[#d2d9fe]"
                                    >
                                    <p className="flex-grow-0 flex-shrink-0 text-[18.024324417114258px] text-center text-[#787b8c]">
                                        Free Reservation
                                    </p>
                                    <svg
                                        width="25"
                                        height="25"
                                        viewBox="0 0 25 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="flex-grow-0 flex-shrink-0 w-[24.03px] h-[24.03px] relative"
                                        preserveAspectRatio="none"
                                    >
                                        <path
                                        d="M21.85 8.04259V6.54056C21.85 6.00941 21.639 5.50002 21.2634 5.12444C20.8878 4.74886 20.3785 4.53786 19.8473 4.53786H5.82839C5.29724 4.53786 4.78784 4.74886 4.41226 5.12444C4.03668 5.50002 3.82568 6.00941 3.82568 6.54056V20.5595C3.82568 21.0906 4.03668 21.6 4.41226 21.9756C4.78784 22.3512 5.29724 22.5622 5.82839 22.5622H9.33312M16.8433 2.53516V6.54056M8.83244 2.53516V6.54056M3.82568 10.546H8.83244M18.3453 18.0561L16.8433 16.8545V14.5514M22.8514 16.5541C22.8514 19.8723 20.1614 22.5622 16.8433 22.5622C13.5251 22.5622 10.8351 19.8723 10.8351 16.5541C10.8351 13.2359 13.5251 10.546 16.8433 10.546C20.1614 10.546 22.8514 13.2359 22.8514 16.5541Z"
                                        stroke="#0019B3"
                                        stroke-width="2.0027"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        ></path>
                                    </svg>
                                    </div>
                                    <div
                                    className="hover:bg-[#f3f7fe] transition duration-300 ease-in-out cursor-pointer flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2.5 px-4 py-3 rounded-[100.14px] bg-white border border-[#d2d9fe]"
                                    >
                                    <p className="flex-grow-0 flex-shrink-0 text-[18.024324417114258px] text-center text-[#787b8c]">
                                        Group Request
                                    </p>
                                    <svg
                                        width="25"
                                        height="25"
                                        viewBox="0 0 25 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="flex-grow-0 flex-shrink-0 w-[24.03px] h-[24.03px] relative"
                                        preserveAspectRatio="xMidYMid meet"
                                    >
                                        <path
                                        d="M8.36377 20.0589C8.36377 19.092 8.69274 18.1139 9.49622 17.576C10.4598 16.9308 11.6206 16.5542 12.8699 16.5542C14.1191 16.5542 15.2799 16.9308 16.2435 17.576C17.047 18.1139 17.3759 19.092 17.3759 20.0589"
                                        stroke="#0019B3"
                                        stroke-width="2.0027"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        ></path>
                                        <path
                                        d="M12.8701 14.0507C14.2527 14.0507 15.3735 12.9299 15.3735 11.5473C15.3735 10.1647 14.2527 9.04395 12.8701 9.04395C11.4875 9.04395 10.3667 10.1647 10.3667 11.5473C10.3667 12.9299 11.4875 14.0507 12.8701 14.0507Z"
                                        stroke="#0019B3"
                                        stroke-width="2.0027"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        ></path>
                                        <path
                                        d="M18.3774 11.5474C19.489 11.5474 20.5218 11.9248 21.3789 12.5711C22.1054 13.1191 22.3828 14.0458 22.3828 14.9558V15.0521"
                                        stroke="#0019B3"
                                        stroke-width="2.0027"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        ></path>
                                        <path
                                        d="M18.3777 9.04398C19.4838 9.04398 20.3804 8.14734 20.3804 7.04128C20.3804 5.93521 19.4838 5.03857 18.3777 5.03857C17.2716 5.03857 16.375 5.93521 16.375 7.04128C16.375 8.14734 17.2716 9.04398 18.3777 9.04398Z"
                                        stroke="#0019B3"
                                        stroke-width="2.0027"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        ></path>
                                        <path
                                        d="M7.36283 11.5474C6.2512 11.5474 5.21839 11.9248 4.36134 12.5711C3.63486 13.1191 3.35742 14.0458 3.35742 14.9558V15.0521"
                                        stroke="#0019B3"
                                        stroke-width="2.0027"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        ></path>
                                        <path
                                        d="M7.36257 9.04398C8.46863 9.04398 9.36527 8.14734 9.36527 7.04128C9.36527 5.93521 8.46863 5.03857 7.36257 5.03857C6.2565 5.03857 5.35986 5.93521 5.35986 7.04128C5.35986 8.14734 6.2565 9.04398 7.36257 9.04398Z"
                                        stroke="#0019B3"
                                        stroke-width="2.0027"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        ></path>
                                    </svg>
                                    </div>
                                    <div
                                    className="hover:bg-[#f3f7fe] transition duration-300 ease-in-out cursor-pointer flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2.5 px-4 py-3 rounded-[100.14px] bg-white border border-[#d2d9fe]"
                                    >
                                    <p className="flex-grow-0 flex-shrink-0 text-[18.024324417114258px] text-center text-[#787b8c]">
                                        Change Option
                                    </p>
                                    <svg
                                        width="25"
                                        height="25"
                                        viewBox="0 0 25 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="flex-grow-0 flex-shrink-0 w-[24.03px] h-[24.03px] relative"
                                        preserveAspectRatio="xMidYMid meet"
                                    >
                                        <path
                                        d="M21.4143 6.04004H10.3994C6.68111 6.04004 3.89062 8.7287 3.89062 12.5488"
                                        stroke="#0019B3"
                                        stroke-width="2.0027"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        ></path>
                                        <path
                                        d="M4.39111 19.0576H15.406C19.1243 19.0576 21.9148 16.369 21.9148 12.5488"
                                        stroke="#0019B3"
                                        stroke-width="2.0027"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        ></path>
                                        <path
                                        d="M19.4116 3.53662C19.4116 3.53662 21.915 5.38033 21.915 6.04002C21.915 6.69971 19.4116 8.54338 19.4116 8.54338"
                                        stroke="#0019B3"
                                        stroke-width="2.0027"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        ></path>
                                        <path
                                        d="M6.39398 16.5542C6.39398 16.5542 3.89064 18.3979 3.89063 19.0576C3.89061 19.7173 6.394 21.561 6.394 21.561"
                                        stroke="#0019B3"
                                        stroke-width="2.0027"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        ></path>
                                    </svg>
                                    </div>
                                    <div
                                    className="hover:bg-[#f3f7fe] transition duration-300 ease-in-out cursor-pointer flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2.5 px-4 py-3 rounded-[100.14px] bg-white border border-[#d2d9fe]"
                                    >
                                    <p className="flex-grow-0 flex-shrink-0 text-[18.024324417114258px] text-center text-[#787b8c]">
                                        Ancillary Services
                                    </p>
                                    <svg
                                        width="25"
                                        height="25"
                                        viewBox="0 0 25 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="flex-grow-0 flex-shrink-0 w-[24.03px] h-[24.03px] relative"
                                        preserveAspectRatio="none"
                                    >
                                        <path
                                        d="M11.2718 7.54203C10.7406 7.54203 10.2312 7.33103 9.85566 6.95545C9.48008 6.57987 9.26908 6.07047 9.26908 5.53932C9.26908 5.00817 9.48008 4.49878 9.85566 4.1232C10.2312 3.74762 10.7406 3.53662 11.2718 3.53662C11.8029 3.53662 12.3123 3.74762 12.6879 4.1232C13.0635 4.49878 13.2745 5.00817 13.2745 5.53932C13.2745 6.07047 13.0635 6.57987 12.6879 6.95545C12.3123 7.33103 11.8029 7.54203 11.2718 7.54203ZM11.2718 7.54203V15.5528H15.2772L19.2826 20.5596M11.2718 11.5474H16.2785M7.26736 12.0481C6.54989 12.3452 5.91137 12.8052 5.40227 13.3916C4.89318 13.978 4.52746 14.6748 4.33405 15.4269C4.14063 16.179 4.12482 16.9657 4.28785 17.725C4.45088 18.4843 4.78829 19.1952 5.27341 19.8016C5.75852 20.408 6.37804 20.8932 7.083 21.2189C7.78795 21.5447 8.55901 21.7019 9.33521 21.6783C10.1114 21.6547 10.8715 21.4509 11.5554 21.0829C12.2392 20.715 12.8281 20.193 13.2755 19.5582"
                                        stroke="#0019B3"
                                        stroke-width="2.0027"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        ></path>
                                    </svg>
                                    </div>
                                    <div
                                    className="hover:bg-[#f3f7fe] transition duration-300 ease-in-out cursor-pointer flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2.5 px-4 py-3 rounded-[100.14px] bg-white border border-[#d2d9fe]"
                                    >
                                    <p className="flex-grow-0 flex-shrink-0 text-[18.024324417114258px] text-center text-[#787b8c]">
                                        Add Infant / Child / UMNR
                                    </p>
                                    <svg
                                        width="25"
                                        height="25"
                                        viewBox="0 0 25 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="flex-grow-0 flex-shrink-0 w-[24.03px] h-[24.03px] relative"
                                        preserveAspectRatio="none"
                                    >
                                        <path
                                        d="M9.30086 12.5488H9.31088M15.309 12.5488H15.319M10.3022 16.5542C10.8029 16.8546 11.5038 17.0549 12.3049 17.0549C13.106 17.0549 13.8069 16.8546 14.3076 16.5542M19.3144 6.84108C20.2216 7.97829 20.84 9.3182 21.1168 10.7464C21.4554 10.9103 21.741 11.1664 21.9408 11.4852C22.1406 11.8039 22.2466 12.1726 22.2466 12.5488C22.2466 12.925 22.1406 13.2936 21.9408 13.6124C21.741 13.9312 21.4554 14.1872 21.1168 14.3512C20.6845 16.3674 19.5739 18.1744 17.9703 19.4707C16.3666 20.7669 14.3669 21.474 12.3049 21.474C10.2429 21.474 8.24323 20.7669 6.63959 19.4707C5.03594 18.1744 3.92532 16.3674 3.49303 14.3512C3.15443 14.1872 2.86887 13.9312 2.66906 13.6124C2.46925 13.2936 2.36328 12.925 2.36328 12.5488C2.36328 12.1726 2.46925 11.8039 2.66906 11.4852C2.86887 11.1664 3.15443 10.9103 3.49303 10.7464C3.90785 8.71411 5.01097 6.88716 6.61627 5.57374C8.22157 4.26031 10.2308 3.54081 12.3049 3.53662C14.3076 3.53662 15.8096 4.63811 15.8096 6.04C15.8096 7.44189 14.9084 8.54338 13.8069 8.54338C13.0059 8.54338 12.3049 8.14284 12.3049 7.54203"
                                        stroke="#0019B3"
                                        stroke-width="2.0027"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        ></path>
                                    </svg>
                                    </div>
                                    <div
                                    className="hover:bg-[#f3f7fe] transition duration-300 ease-in-out cursor-pointer flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2.5 px-4 py-3 rounded-[100.14px] bg-white border border-[#d2d9fe]"
                                    >
                                    <p className="flex-grow-0 flex-shrink-0 text-[18.024324417114258px] text-center text-[#787b8c]">
                                        Extra Seat
                                    </p>
                                    <svg
                                        width="25"
                                        height="25"
                                        viewBox="0 0 25 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="flex-grow-0 flex-shrink-0 w-[24.03px] h-[24.03px] relative"
                                        preserveAspectRatio="xMidYMid meet"
                                    >
                                        <path
                                        d="M8.81489 18.5568H18.3182C19.4396 18.5568 20.3488 17.6633 20.3488 16.5612C20.3488 15.052 18.3182 14.5655 18.3182 14.5655C18.3182 14.5655 14.6254 13.1459 10.3353 14.5514C10.3353 14.5514 10.1961 9.41718 8.04201 3.70741C7.61702 2.58088 6.23091 2.19625 5.21373 2.8627C4.54251 3.30249 4.20769 4.09692 4.3659 4.87436L6.82379 16.9525C7.0136 17.8854 7.84697 18.5568 8.81489 18.5568Z"
                                        stroke="#0019B3"
                                        stroke-width="2.0027"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        ></path>
                                        <path
                                        d="M12.8384 11.0469H18.3458"
                                        stroke="#0019B3"
                                        stroke-width="2.0027"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        ></path>
                                        <path
                                        d="M16.3433 18.5566L13.3393 22.562M13.3393 22.562H8.33252M13.3393 22.562H18.346"
                                        stroke="#0019B3"
                                        stroke-width="2.0027"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        ></path>
                                    </svg>
                                    </div>
                                    <div
                                    className="hover:bg-[#f3f7fe] transition duration-300 ease-in-out cursor-pointer flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2.5 px-4 py-3 rounded-[100.14px] bg-white border border-[#d2d9fe]"
                                    >
                                    <p className="flex-grow-0 flex-shrink-0 text-[18.024324417114258px] text-center text-[#787b8c]">
                                        Free Change
                                    </p>
                                    <svg
                                        width="25"
                                        height="25"
                                        viewBox="0 0 25 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="flex-grow-0 flex-shrink-0 w-[24.03px] h-[24.03px] relative"
                                        preserveAspectRatio="xMidYMid meet"
                                    >
                                        <path
                                        d="M17.3767 3.87478C15.9038 3.02278 14.1938 2.53516 12.37 2.53516C6.83965 2.53516 2.35645 7.01836 2.35645 12.5487C2.35645 18.0789 6.83965 22.5622 12.37 22.5622C17.9002 22.5622 22.3835 18.0789 22.3835 12.5487C22.3835 11.8628 22.3145 11.193 22.1832 10.546"
                                        stroke="#0019B3"
                                        stroke-width="2.0027"
                                        stroke-linecap="round"
                                        ></path>
                                        <path
                                        d="M8.36475 13.0492C8.36475 13.0492 9.86677 13.0492 11.8695 16.5539C11.8695 16.5539 17.4358 7.37487 22.3837 5.53906"
                                        stroke="#0019B3"
                                        stroke-width="2.0027"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        ></path>
                                    </svg>
                                    </div>
                                    <div
                                    className="hover:bg-[#f3f7fe] transition duration-300 ease-in-out cursor-pointer flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2.5 px-4 py-3 rounded-[100.14px] bg-white border border-[#d2d9fe]"
                                    >
                                    <p className="flex-grow-0 flex-shrink-0 text-[18.024324417114258px] text-center text-[#787b8c]">
                                        Flexible Payment Methods
                                    </p>
                                    <svg
                                        width="25"
                                        height="25"
                                        viewBox="0 0 25 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="flex-grow-0 flex-shrink-0 w-[24.03px] h-[24.03px] relative"
                                        preserveAspectRatio="xMidYMid meet"
                                    >
                                        <path
                                        d="M3.73486 16.7506L16.5812 3.9043M19.0414 11.6016L16.8389 13.8041M14.9592 15.6614L13.9792 16.6414"
                                        stroke="#0019B3"
                                        stroke-width="2.0027"
                                        stroke-linecap="round"
                                        ></path>
                                        <path
                                        d="M3.56542 16.6954C1.99707 15.1271 1.99707 12.5842 3.56542 11.0159L10.8699 3.71142C12.4382 2.14307 14.9811 2.14307 16.5494 3.71142L21.2399 8.40199C22.8083 9.97033 22.8083 12.5131 21.2399 14.0814L13.9354 21.3859C12.3671 22.9543 9.82434 22.9543 8.25599 21.3859L3.56542 16.6954Z"
                                        stroke="#0019B3"
                                        stroke-width="2.0027"
                                        ></path>
                                        <path
                                        d="M4.39209 22.562H20.4137"
                                        stroke="#0019B3"
                                        stroke-width="2.0027"
                                        stroke-linecap="round"
                                        ></path>
                                    </svg>
                                    </div>
                                    <div
                                    className="hover:bg-[#f3f7fe] transition duration-300 ease-in-out cursor-pointer flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2.5 px-4 py-3 rounded-[100.14px] bg-white border border-[#d2d9fe]"
                                    >
                                    <p className="flex-grow-0 flex-shrink-0 text-[18.024324417114258px] text-center text-[#787b8c]">
                                        Instant Refund
                                    </p>
                                    <svg
                                        width="25"
                                        height="25"
                                        viewBox="0 0 25 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="flex-grow-0 flex-shrink-0 w-[24.03px] h-[24.03px] relative"
                                        preserveAspectRatio="none"
                                    >
                                        <path
                                        d="M7.81417 14.5516C6.772 15.14 5.92928 16.0262 5.39406 17.0966C4.85884 18.1671 4.65551 19.3729 4.81012 20.5597C5.99688 20.7143 7.20277 20.511 8.27321 19.9758C9.34366 19.4405 10.2299 18.5978 10.8182 17.5557M4.81062 13.5502C6.59612 13.7624 8.25832 14.5692 9.52974 15.8406C10.8012 17.112 11.6079 18.7742 11.8201 20.5597C12.7052 20.0494 13.4456 19.3219 13.9712 18.4458C14.4969 17.5697 14.7904 16.5741 14.8241 15.553C16.5055 14.9615 17.9737 13.8847 19.0432 12.4588C20.1126 11.0329 20.7352 9.32188 20.8322 7.54214C20.8322 6.74542 20.5157 5.98132 19.9524 5.41795C19.389 4.85458 18.6249 4.53809 17.8282 4.53809C16.0484 4.63512 14.3374 5.25772 12.9115 6.32715C11.4856 7.39658 10.4089 8.86481 9.81737 10.5462C8.79626 10.5799 7.80061 10.8735 6.92453 11.3991C6.04846 11.9248 5.32089 12.6651 4.81062 13.5502ZM14.8241 9.54484C14.8241 9.81042 14.9296 10.0651 15.1174 10.2529C15.3052 10.4407 15.5599 10.5462 15.8255 10.5462C16.0911 10.5462 16.3458 10.4407 16.5335 10.2529C16.7213 10.0651 16.8268 9.81042 16.8268 9.54484C16.8268 9.27927 16.7213 9.02457 16.5335 8.83678C16.3458 8.64899 16.0911 8.54349 15.8255 8.54349C15.5599 8.54349 15.3052 8.64899 15.1174 8.83678C14.9296 9.02457 14.8241 9.27927 14.8241 9.54484Z"
                                        stroke="#0019B3"
                                        stroke-width="2.0027"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        ></path>
                                    </svg>
                                    </div>
                                    <div
                                    className="hover:bg-[#f3f7fe] transition duration-300 ease-in-out cursor-pointer flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2.5 px-4 py-3 rounded-[100.14px] bg-white border border-[#d2d9fe]"
                                    >
                                    <p className="flex-grow-0 flex-shrink-0 text-[18.024324417114258px] text-center text-[#787b8c]">
                                        Cancelled and Delayed Flights Full Support
                                    </p>
                                    <svg
                                        width="25"
                                        height="25"
                                        viewBox="0 0 25 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="flex-grow-0 flex-shrink-0 w-[24.03px] h-[24.03px] relative"
                                        preserveAspectRatio="none"
                                    >
                                        <path
                                        d="M4.84326 13.5502C5.1687 16.0856 6.7268 18.3377 8.84867 19.5583M20.8649 11.5482C20.62 9.78605 19.8025 8.15329 18.5384 6.90143C17.2742 5.64958 15.6336 4.84808 13.8691 4.62041C12.1046 4.39274 10.3143 4.75152 8.77377 5.64148C7.23327 6.53145 6.02811 7.90322 5.34394 9.54551M4.84326 5.53942V9.54482H8.84867M12.8541 15.5529H14.8568C15.1224 15.5529 15.377 15.6584 15.5648 15.8462C15.7526 16.034 15.8581 16.2887 15.8581 16.5543V17.5556C15.8581 17.8212 15.7526 18.0759 15.5648 18.2637C15.377 18.4515 15.1224 18.557 14.8568 18.557H13.8554C13.5898 18.557 13.3352 18.6625 13.1474 18.8503C12.9596 19.0381 12.8541 19.2928 12.8541 19.5583V20.5597C12.8541 20.8253 12.9596 21.08 13.1474 21.2677C13.3352 21.4555 13.5898 21.561 13.8554 21.561H15.8581M18.8622 15.5529V17.5556C18.8622 17.8212 18.9677 18.0759 19.1555 18.2637C19.3433 18.4515 19.598 18.557 19.8635 18.557H20.8649M21.8662 15.5529V21.561"
                                        stroke="#0019B3"
                                        stroke-width="2.0027"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        ></path>
                                    </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex-1 z-0 absolute top-[0px] left-1/2 -translate-x-1/2 w-full h-full">
                        <div className="flex flex-wrap justify-center items-center w-full h-full">
                            <img
                                src={servicesAllBackground}
                                draggable="false"
                                alt=""
                                className="w-full h-full select-none"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};